import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Input from '../../../../componentsV2/DesignSystem/Inputs/Input';
import useAccount from '../../../../hooks/useAccount';
import useToast from '../../../../hooks/useToast';
import styles from './styles.module.scss';
import { useSendEmailTestMutation } from './useSendEmailTestMutation';

export default function SendEmailTest(): JSX.Element {
  const { sendEmail, loading: loadingSendEmailTest, data: dataSendEmailTest } = useSendEmailTestMutation();

  const {
    register,
    watch,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { getAccount } = useAccount();
  const { toast } = useToast();

  useEffect(() => {
    if (dataSendEmailTest) {
      toast('success', 'Test Email Sent Successfully!');
    }
  }, [dataSendEmailTest]);

  const [testEmailToValue, subjectValue] = watch(['testEmailTo', 'subject']);

  useEffect(() => {
    clearErrors(testEmailToValue);
  }, [testEmailToValue]);

  useEffect(() => {
    clearErrors(subjectValue);
  }, [subjectValue]);

  const sendEmailTest = async () => {
    const testEmailTo = getValues('testEmailTo');
    const subject = getValues('subject');

    if (subject && testEmailTo) {
      const content = getValues('content');
      const greeting = getValues('greeting');
      const enter_button_text = getValues('enter_button_text');
      const signature = getValues('signature');

      sendEmail({
        agencyId: getAccount.agencyId,
        accountId: getAccount.accountId,
        testEmailTo,
        data: {
          content,
          subject,
          greeting,
          enter_button_text,
          signature,
        },
      });
    } else {
      if (subject === '') {
        setError('subject', { type: 'required' });
      }

      if (testEmailTo === '') {
        setError('testEmailTo', { type: 'required' });
      } else if (!isValidEmail(testEmailTo)) {
        setError('testEmailTo', { type: 'validate', message: 'Invalid email address' });
      }
    }
  };

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className={styles['send-email-test']}>
      <div>
        <Input
          {...register('testEmailTo')}
          label="Send a test"
          placeholder="Enter email address"
          {...(errors.testEmailTo && {
            helperText: errors.testEmailTo.message,
            color: 'danger',
          })}
          inputSize="sm"
        />
      </div>
      <div>
        <Button
          type="button"
          size="sm"
          variant="secondary"
          onClick={sendEmailTest}
          loading={loadingSendEmailTest}
          disabled={loadingSendEmailTest || !testEmailToValue || testEmailToValue?.length === 0}
        >
          Send Email Test
        </Button>
      </div>
    </div>
  );
}
