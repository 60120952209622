import { useFormContext } from 'react-hook-form';
import HtmlHeader from '../../../../../../../components/_molecules/HtmlHeader';
import Icon from '../../../../../../../componentsV2/DesignSystem/Icon';
import IconPluginBg from '../../HtmlBackgrounds/IconPluginBg';
import { IconPreviewProps } from './inferfaces';

import styles from './styles.module.scss';

export default function IconPreview({ accountSlug, imageUrl }: IconPreviewProps): JSX.Element {
  const { watch } = useFormContext();
  const [brand_color] = watch(['brand_color']);

  return (
    <>
      <HtmlHeader urlText={`www.${accountSlug}.com`} />

      <div className={styles['icon_html_page']}>
        <IconPluginBg />
        <div
          className={styles['icon_outline']}
          style={{
            background: `linear-gradient(-20deg, ${brand_color}CC, #FFFFFF1F)`,
            boxShadow: `0 4px 12px 0 ${brand_color}33`,
          }}
        >
          <div
            className={styles['close_button']}
            style={{
              color: brand_color,
              border: `1px solid ${brand_color}1A`,
            }}
          >
            <Icon customClass={styles['close-icon']}>close</Icon>
          </div>
          <div
            className={styles['icon_container']}
            style={{
              background: `linear-gradient(-45deg, ${brand_color}1F, #FFFFFF)`,
            }}
          >
            <img className={styles['icon-image']} src={imageUrl} alt="preview icon" />
            <div
              className={styles['gift_count']}
              style={{
                backgroundColor: brand_color,
              }}
            >
              <Icon customClass={styles['gift-icon']}>featured_seasonal_and_gifts</Icon>
              10
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
