import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import ButtonCopy from '../../../../../../../componentsV2/ButtonCopy';
import ButtonTooltip from '../../../../../../../componentsV2/ButtonTooltip';
import { Button } from '../../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import TableColumnsControl from '../../../../../../../componentsV2/TableColumnsControl';
import {
  TableDataCellSerialized,
  TableHeaderElement,
} from '../../../../../../../componentsV2/TableColumnsControl/interface';
import styleModuleClasses from '../../../../../../../utils/styleModuleClasses';
import SendEmailModal from '../../../../ManageInviteLinks/SendEmailModal';
import { SendEmailProvider } from '../../../../SendEmail/SendEmailProvider';
import useShareLinks, { InviteListDataPersonalized } from '../../../ShareLinksProvider';
import { DEFAULT_COLUMNS } from './DefaultColumns';
import PopoverDetails from './PopoverDetails';
import styles from './styles.module.scss';

type Tab = 'link' | 'qr_code';

export default function ListSingleInviteLinks(): JSX.Element {
  const disabledSendEmail = true;
  const [serializedTable, setSerializedTable] = useState<TableHeaderElement[]>();
  const [tab, setTab] = useState<Tab>('link');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<{ code: string; invitelinkUrl: string; firstName?: string }>();

  const { inviteListDataPersonalized } = useShareLinks();

  // Serialize data to table
  const getSerealized = (dataInviteLink: InviteListDataPersonalized[]) => {
    return dataInviteLink.map((value) => {
      return {
        created_at: !!value.created_at ? format(new Date(value.created_at), 'dd MMM yyyy - HH:mm') : '',
        invitelinks: value.url,
        code: value.code,
        email: value.email || '',
        expire_date: !!value.expire_date ? format(new Date(value.expire_date), 'dd MMM yyyy - HH:mm') : '',
      } as TableDataCellSerialized;
    });
  };

  const buildTableResult = (
    header: TableHeaderElement[],
    contentTable: TableDataCellSerialized[],
  ): TableHeaderElement[] => {
    return header.map((column) => {
      const data = contentTable.map((item) => {
        return {
          message: (item[column.id as keyof TableDataCellSerialized] || '').toString(),
        };
      });
      const type = tab === 'link' ? 'text' : 'qr_code';
      return { ...column, type: column.id === 'invitelinks' ? type : column.type, data };
    });
  };

  // Build data table
  useEffect(() => {
    const dataSerealized = getSerealized(inviteListDataPersonalized);
    const result: TableHeaderElement[] = buildTableResult(DEFAULT_COLUMNS, dataSerealized);
    setSerializedTable(result);
  }, [inviteListDataPersonalized]);

  const tableActionButtons = useMemo(() => {
    if (inviteListDataPersonalized) {
      return inviteListDataPersonalized.map((invite) => (
        <>
          <PopoverDetails invite={invite} />
          <ButtonCopy text={invite.url} hoverMessage="Copy InviteLink URL" onClickMessage="Copied InviteLink URL" />
          {invite.email && !disabledSendEmail && (
            <ButtonTooltip
              tooltip="Share InviteLink by Email"
              size="sm"
              variant="secondary"
              firstIcon={{ children: 'email' }}
              onClick={() => openSendEmailModal(invite.code, invite.url, invite?.first_name)}
            />
          )}
        </>
      ));
    }
    return [];
  }, [inviteListDataPersonalized]);

  const openSendEmailModal = (code: string, invitelinkUrl: string, firstName?: string) => {
    setModalData({ code, invitelinkUrl, firstName });
    setOpenModal(true);
  };

  const handleTab = (nav: Tab) => {
    setTab(nav);
    const table = serializedTable?.map((column) => {
      const type = nav === 'link' ? 'text' : 'qr_code';
      return {
        ...column,
        type: column.id === 'invitelinks' ? type : column.type,
      };
    });

    setSerializedTable(table);
  };

  return (
    <>
      {inviteListDataPersonalized.length > 0 && (
        <>
          <nav className={styles['tab-wrapper']}>
            <Button
              onClick={() => handleTab('link')}
              variant="tertiary"
              firstIcon={{ children: 'link' }}
              customClass={styleModuleClasses(styles, {
                active: tab === 'link',
              })}
            >
              Links
            </Button>
            <Button
              onClick={() => handleTab('qr_code')}
              variant="tertiary"
              firstIcon={{ children: 'qr_code' }}
              customClass={styleModuleClasses(styles, {
                active: tab === 'qr_code',
              })}
            >
              QR Codes
            </Button>
          </nav>

          <TableColumnsControl
            column={serializedTable}
            loading={false}
            actionButtons={tableActionButtons}
            lineSize={tab === 'qr_code' ? 'lg' : undefined}
            lastColumnFixed={true}
          />
        </>
      )}
      {openModal && modalData?.code && (
        <SendEmailProvider>
          <SendEmailModal
            setOpenModal={setOpenModal}
            code={modalData.code}
            invitelinkUrl={modalData?.invitelinkUrl}
            firstName={modalData?.firstName}
          />
        </SendEmailProvider>
      )}
    </>
  );
}
