import React, { useEffect, useImperativeHandle, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Button } from '../../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import SpinnerBox from '../../../../../../../componentsV2/DesignSystem/SpinnerBox';
import { FontWeight, Paragraph } from '../../../../../../../componentsV2/DesignSystem/Typography';
import useAccount from '../../../../../../../hooks/useAccount';
import { ShareInviteIndividually } from '../../../../../../../ts';
import useShareLinks from '../../../ShareLinksProvider';
import Item from './Item';
import SelectNewField from './SelectNewField';
import { IndividuallyProps, IndividuallyRef } from './interface';
import styles from './styles.module.scss';

export default React.forwardRef<IndividuallyRef, IndividuallyProps>(function Individually(
  { requestIndividuallyInvite, dataInvite }: IndividuallyProps,
  ref,
) {
  const {
    promotionSelected,
    pushNewInviteLink,
    setLoadingPersonalized,
    setInviteMethodPersonalized,
    inviteMethodPersonalized,
    clearFields,
    setInviteTypeListMethod,
    setGeneratorId,
    seDownloadLink,
    inviteLinkCreated,
    uidField,
    setUidField,
    getUidFromlistFields,
    getFormFieldsWithUidFieldList,
    setDefaultUidField,
  } = useShareLinks();

  const { getAccount, loadingFields, listFields, getDataFields } = useAccount();

  const listFieldsFormated = useMemo(
    () =>
      listFields?.map((filed) => ({
        label: filed.name,
        value: filed.slug,
      })) || [],
    [listFields],
  );

  const methods = useForm<ShareInviteIndividually>({
    defaultValues: {
      agency_id: getAccount.agencyId,
      promo_id: promotionSelected.id.toString(),
      fields: [
        { slug: 'first-name', value: '' },
        { slug: 'last-name', value: '' },
        { slug: 'email', value: '' },
      ],
    },
  });

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
    watch,
  } = methods;

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'fields',
    keyName: '__id',
  });

  useEffect(() => getDataFields(), []);

  const watchFieldsData = watch('fields');

  const findFieldValue = watchFieldsData.find((field) => field.value !== '');

  const uidFields = useMemo(() => getUidFromlistFields(listFields), [listFields]);

  const showUidField = useMemo(() => {
    if (!uidFields || !watchFieldsData) {
      return false;
    }
    const fieldsData = getFormFieldsWithUidFieldList(watchFieldsData, uidFields);
    return fieldsData.length > 1;
  }, [uidFields, watchFieldsData]);

  useEffect(() => {
    if (uidFields && watchFieldsData) {
      const fieldsData = getFormFieldsWithUidFieldList(watchFieldsData, uidFields);
      if (fieldsData?.[0]?.slug) {
        setDefaultUidField(fieldsData?.[0]?.slug);
      } else {
        setUidField(undefined);
      }
    }
  }, [uidFields, watchFieldsData]);

  const handleInputField = (input: 'blur' | 'focus') => {
    if (input === 'focus') {
      setInviteMethodPersonalized('individually');
    } else {
      if (findFieldValue?.value) {
        setInviteMethodPersonalized('individually');
      }
      if (!findFieldValue) {
        setInviteMethodPersonalized(null);
      }
    }
  };

  useEffect(() => {
    if (dataInvite) {
      pushNewInviteLink(dataInvite, listFields);

      clearIndividually();

      document.getElementById('fields.0.value')?.focus();
      setLoadingPersonalized(false);
      setInviteTypeListMethod('individually');

      setGeneratorId(null);
      seDownloadLink(undefined);

      inviteLinkCreated();
    }
  }, [dataInvite]);

  useEffect(() => {
    if (clearFields) {
      clearIndividually();
    }
  }, [clearFields]);

  const clearIndividually = () => {
    fields.map((_, index) => setValue(`fields.${index}.value`, ''));
    setInviteMethodPersonalized(null);
    setUidField(undefined);
  };

  const checkDuplicateFields = () => {
    const fields = getValues('fields');
    fields?.map((item, index) => {
      clearErrors(`fields.${index}.value`);
      const total = fields?.filter((value) => item.slug && value.slug === item.slug);
      if (total.length > 1) {
        setError(`fields.${index}.value`, {
          type: 'validate',
          message: 'There are duplicate fields, please check.',
        });
      }
    });
  };

  const onSubmit = handleSubmit((variables) => {
    const fields = variables.fields[0]?.slug
      ? variables.fields.reduce((acc: Record<string, string>, field) => {
          if (field.value !== '') {
            acc[field.slug] = field.value;
          }
          return acc;
        }, {})
      : undefined;

    requestIndividuallyInvite(fields);
  });

  const appendField = (slug: string) => {
    append({
      slug,
      value: '',
    });
  };

  const updateField = (index: number, slug: string) => {
    const oldUidField = getValues(`fields.${index}.slug`);
    if (oldUidField !== slug) {
      setValue(`fields.${index}.slug`, slug);
      if (oldUidField === uidField || !uidField) {
        setUidField(undefined);
        const fieldsData = getFormFieldsWithUidFieldList(getValues('fields'), uidFields);
        setDefaultUidField(fieldsData?.[0]?.slug);
      }
    }
  };

  const removeField = (index: number) => {
    remove(index);
    checkDuplicateFields();
  };

  const generateInviteLink = () => {
    checkDuplicateFields();

    if (Object.keys(errors).length) {
      return false;
    }

    onSubmit();
  };

  const submitForm = () => {
    generateInviteLink();
  };

  useImperativeHandle(ref, () => ({
    submitForm: submitForm,
  }));

  const goToInBulk = () => {
    clearIndividually();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={generateInviteLink} noValidate method="post">
        <div className={styles['wrapper-single-link']}>
          <div className={styles['header-content']}>
            <div>
              <Paragraph size={1} weight={FontWeight.extraBold}>
                Create Individually
              </Paragraph>
              <Paragraph size={2}>
                Create a single InviteLink which you can then copy and send to someone to allow them one entry to your
                promotion.
              </Paragraph>
            </div>
            <div>
              {inviteMethodPersonalized === 'individually' && (
                <Button firstIcon={{ children: 'groups' }} size="sm" variant="secondary" onClick={goToInBulk}>
                  Create in Bulk
                </Button>
              )}
            </div>
          </div>
          {loadingFields ? (
            <div className={styles['spinner']}>
              <SpinnerBox />
            </div>
          ) : (
            fields.map((_, index: number) => (
              <Item
                key={index}
                index={index}
                removeField={removeField}
                updateField={updateField}
                handleInputField={handleInputField}
                listFields={listFields}
                showUidField={showUidField}
              />
            ))
          )}
          <SelectNewField appendField={appendField} listFields={listFieldsFormated} />
        </div>
      </form>
    </FormProvider>
  );
});
