import { InputHTMLAttributes } from 'react';
import styleModuleClasses from '../../../../utils/styleModuleClasses';
import styles from './styles.module.scss';

/* eslint-disable @typescript-eslint/no-empty-interface */
interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {}

export default function Switch(props: SwitchProps): JSX.Element {
  return (
    <div className={styleModuleClasses(styles, 'switch', 'form-check', 'form-switch')}>
      <input className="form-check-input form-switch form-switch-md mt-0 mb-0" type="checkbox" {...props} />
    </div>
  );
}
