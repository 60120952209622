import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FontWeight, Paragraph } from '../../../../componentsV2/DesignSystem/Typography';
import useAccount from '../../../../hooks/useAccount';
import SendEmailTest from './SendEmailTest';
import styles from './styles.module.scss';

interface PreviewEmailProps {
  firstName?: string;
  invitelinkUrl?: string;
}

export default function PreviewEmail({ firstName, invitelinkUrl }: PreviewEmailProps): JSX.Element {
  const { getAccount } = useAccount();

  const { watch } = useFormContext();

  const [subject, greeting, content, enter_button_text, signature] = watch([
    'subject',
    'greeting',
    'content',
    'enter_button_text',
    'signature',
  ]);

  return (
    <div className={styles['column-container']}>
      <Paragraph size={2} weight={FontWeight.semiBold}>
        Email Preview
      </Paragraph>

      <div className={styles['preview-header']}>
        <div>
          <Paragraph size={3} weight={FontWeight.semiBold} color="gray-48">
            From Address
          </Paragraph>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            promo@beeliked.com
          </Paragraph>
        </div>

        <div>
          <Paragraph size={3} weight={FontWeight.semiBold} color="gray-48">
            From Name
          </Paragraph>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            {getAccount.accountName}
          </Paragraph>
        </div>

        <div>
          <Paragraph size={3} weight={FontWeight.semiBold} color="gray-48">
            Subject
          </Paragraph>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            {subject}
          </Paragraph>
        </div>
      </div>

      <div className={styles['content']}>
        <div className={styles['blank']}>
          <div className={styles['greeting']}>
            <div>{greeting}</div>
            <div className={styles['user-name']}>{firstName}</div>
          </div>
          <div className={styles['email-content']}>
            {content.split('\n').map((item: string, key: number) => (
              <React.Fragment key={key}>
                {item}
                <br />
              </React.Fragment>
            ))}
          </div>

          <div className={styles['enter-button-container']}>
            {enter_button_text && (
              <div>
                <div className={styles['enter-button']}>{enter_button_text}</div>
              </div>
            )}

            <div className={styles['invite-link']}>{invitelinkUrl}</div>
          </div>
          <div className={styles['email-signature']}>
            <div>
              {signature.split('\n').map((item: string, key: number) => (
                <React.Fragment key={key}>
                  {item}
                  <br />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      <SendEmailTest />
    </div>
  );
}
