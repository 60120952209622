import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Input from '../../../../componentsV2/DesignSystem/Inputs/Input';
import Textarea from '../../../../componentsV2/DesignSystem/Inputs/Textarea';
import { FontWeight, Paragraph } from '../../../../componentsV2/DesignSystem/Typography';
import SendEmailTest from './SendEmailTest';
import styles from './styles.module.scss';

export default function ConfigureEmail(): JSX.Element {
  const {
    register,
    formState: { errors },
    clearErrors,
    watch,
  } = useFormContext();

  const subjectField = watch('subject');

  useEffect(() => {
    clearErrors('subject');
  }, [subjectField]);

  return (
    <>
      <div className={styles['column']}>
        <div className={styles['configure-paragraphs']}>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            Configure the email sending options
          </Paragraph>

          <Paragraph size={2}>Customize your email sending preferences . E.g. Name, subject, etc.</Paragraph>
        </div>
        <div className={styles['form-inputs']}>
          <Input label="From Address" placeholder="promo@beeliked.com" disabled inputSize="sm" />
          <Input
            {...register('subject')}
            label="Subject"
            placeholder="What's the subject line for this email..."
            {...(errors.subject && { helperText: 'Please provide a valid value.', color: 'danger' })}
            inputSize="sm"
          />
        </div>
        <hr />
        <div className={styles['form-inputs']}>
          <Input
            {...register('greeting')}
            label="Greeting"
            placeholder="Hi"
            {...(errors.greeting && { helperText: errors.greeting.message, color: 'danger' })}
            inputSize="sm"
          />
          <Textarea
            {...register('content')}
            label="Email Content"
            placeholder="You've been invited  into the draw to win our grand prize. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            {...(errors.greeting && { helperText: errors.greeting.message, color: 'danger' })}
            inputSize="sm"
            rows={5}
          />

          <Input
            {...register('enter_button_text')}
            label="Enter Button"
            placeholder="Play Now"
            {...(errors.enter_button_text && {
              helperText: errors.enter_button_text.message,
              color: 'danger',
            })}
            inputSize="sm"
          />
          <Textarea
            {...register('signature')}
            label="Thank you"
            placeholder="Thank you,&#10;The Clermont Team"
            {...(errors.signature && { helperText: errors.signature.message, color: 'danger' })}
            inputSize="sm"
            rows={3}
          />
        </div>
      </div>
      <div className={styles['column']}>
        <div className={styles['column-container']}>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            Email Content
          </Paragraph>
          <div className={styles['content']}>
            <div className={styles['blank']}>
              <div className={styles['greeting']}>
                <div className={styles['placeholder']}>Greeting</div>
                <div className={styles['user-name']}>
                  {`{{`}User_name{`}}`},
                </div>
              </div>

              <div className={styles['email-content']}>
                <div className={styles['placeholder']}>Email Content</div>
                <div className={styles['placeholder']}>&nbsp;</div>
                <div className={styles['placeholder']}>&nbsp;</div>
                <div className={styles['placeholder']}>&nbsp;</div>
              </div>
              <div className={styles['enter-button-container']}>
                <div>
                  <div className={styles['enter-button']}>Enter Button</div>
                </div>
                <div className={styles['invite-link']}>
                  {`{{`}Invite_link{`}}`}
                </div>
              </div>
              <div className={styles['email-signature']}>
                <div>
                  <div className={styles['placeholder']}>Email Signature</div>
                </div>
                <div>
                  <div className={styles['placeholder']}>&nbsp;</div>
                </div>
              </div>
            </div>
            <div className={styles['footer']}>
              <Paragraph size={3} weight={FontWeight.semiBold}>
                The Promoter of this promotion is BeeLiked.
              </Paragraph>
              <Paragraph size={3} weight={FontWeight.semiBold}>
                Should you need any support, please click here and fill out the form.
              </Paragraph>
            </div>
          </div>
          <SendEmailTest />
        </div>
      </div>
    </>
  );
}
