import FormEmail from './FormEmail';
import PreviewEmail from './PreviewContent';
import styles from './styles.module.scss';

interface PreviewSendProps {
  invitelinkUrl?: string;
  firstName?: string;
}

export default function PreviewSend({ invitelinkUrl, firstName }: PreviewSendProps): JSX.Element {
  return (
    <>
      <div className={styles['column']}>
        <FormEmail />
      </div>
      <div className={styles['column']}>
        <PreviewEmail invitelinkUrl={invitelinkUrl} firstName={firstName} />
      </div>
    </>
  );
}
