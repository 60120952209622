import React, { useEffect, useImperativeHandle, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useAccount from '../../../../../../../hooks/useAccount';
import { FieldsInviteFileMapper, InviteInbulkFileMapperInput } from '../../../../../../../ts';
import useShareLinks from '../../../ShareLinksProvider';
import { useCreateInbulkFileInviteMutation } from '../useCreateInbulkFileInviteMutation';
import EditFields from './EditFields';
import UploadCsv from './UploadCsv';
import { InBulkProps, InBulkRef } from './interface';

export default React.forwardRef<InBulkRef, InBulkProps>(function InBulk(
  {
    validation,
    setValidation,
    fileInbulk,
    setFileInbulk,
    requestInbulkFileMapper,
    inbulkStep,
    setInbulkStep,
    clearFileInbulk,
  }: InBulkProps,
  ref,
) {
  const { getAccount } = useAccount();

  const { promotionSelected, setQuantityInBulk, setGeneratorId, setLoadingPersonalized, generatorId, seDownloadLink } =
    useShareLinks();

  const { loading, createInbulkFileInvite, data } = useCreateInbulkFileInviteMutation();

  const formMethods = useForm<InviteInbulkFileMapperInput>({
    defaultValues: {
      agency_id: getAccount.agencyId,
      account_id: getAccount.accountId,
      generator_id: generatorId,
      disabledField: [],
      name: '',
    },
  });

  const { handleSubmit, setValue } = formMethods;

  useEffect(() => {
    if (data && data.id) {
      setGeneratorId(data.id);
      setQuantityInBulk(data.quantity);
      setLoadingPersonalized(false);
      setInbulkStep('mapper');
      if (data.file_headers) {
        const disabledField: boolean[] = [];
        data.file_headers.forEach(() => disabledField.push(false));
        setValue('disabledField', disabledField);
      }
    }
  }, [data]);

  useEffect(() => {
    if (fileInbulk.file) {
      setLoadingPersonalized(true);
      createInbulkFileInvite({
        agency_id: getAccount.agencyId,
        account_id: getAccount.accountId,
        promo_id: promotionSelected.id,
        url: promotionSelected.micrositeUrl,
        file: fileInbulk.file,
      });
      setGeneratorId(null);
      seDownloadLink(undefined);
    }
  }, [fileInbulk]);

  const fileHeaders = useMemo(() => data?.file_headers ?? [], [data?.file_headers]);

  const onSubmit = handleSubmit((variables) => {
    const data = variables.data.reduce((acc: Record<string, string | number>[], field: FieldsInviteFileMapper) => {
      acc.push({ slug: field.slug ?? null, order: acc.length });
      return acc;
    }, []);

    requestInbulkFileMapper(data, variables.name);
  });

  const submitForm = () => {
    onSubmit();
  };

  useImperativeHandle(ref, () => ({
    submitForm: submitForm,
  }));

  return inbulkStep === 'upload' ? (
    <UploadCsv
      filename={fileInbulk.name}
      setFileInbulk={setFileInbulk}
      validation={validation}
      setValidation={setValidation}
      loading={loading}
      clearFileInbulk={clearFileInbulk}
    />
  ) : (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit} noValidate method="post">
        <EditFields fileHeaders={fileHeaders} name={fileInbulk.name} />
      </form>
    </FormProvider>
  );
});
