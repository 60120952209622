import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../../../../../../componentsV2/DesignSystem/Dropdown';
import { Button } from '../../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import ButtonConfirm from '../../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonConfirm';
import ButtonNavigation from '../../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';
import ModalDuplicate from '../../../../../../../componentsV2/ModalDuplicate';
import { REACT_APP_MONOLITH_V2 } from '../../../../../../../config/Env';
import useAccount from '../../../../../../../hooks/useAccount';
import { PromoteStatus } from '../../../../../../../ts';
import styles from './styles.module.scss';

export interface Props {
  promoId: number;
  promoStatus: string;
  micrositeUrl: string;
  passcode: string;
  loadingDeletePromotion: boolean;
  toggleConfirmDelete: (promoId: number) => void;
}

export default function PromotionDropDown({
  promoId,
  promoStatus,
  micrositeUrl,
  passcode,
  loadingDeletePromotion,
  toggleConfirmDelete,
}: Props): JSX.Element {
  const [showModalDuplicate, setShowModalDuplicate] = useState<boolean>(false);

  const { t } = useTranslation('promotionsList', { keyPrefix: 'dropdown_actions_menu' });

  const { getAccount } = useAccount();

  const micrositeUrlKey = `${micrositeUrl}${
    promoStatus === (PromoteStatus.DRAFT || PromoteStatus.SCHEDULED) ? `?key=${passcode}` : ``
  }`;

  const toggleModal = () => setShowModalDuplicate((showModalDuplicate) => !showModalDuplicate);

  return (
    <>
      {showModalDuplicate && <ModalDuplicate promoId={promoId} callback={toggleModal} origin="list" />}
      <Dropdown
        activeButton={{
          button: { variant: 'tertiary', size: 'sm', text: { color: 'dark' }, firstIcon: { children: 'more_vert' } },
        }}
        placement="left-start"
        size="sm"
      >
        <ul className={[styles['promotion-dropdown']].join(' ')}>
          {promoStatus !== PromoteStatus.DRAFT && promoStatus !== PromoteStatus.SCHEDULED && (
            <>
              <li>
                <ButtonNavigation
                  to={{ pathname: micrositeUrlKey }}
                  firstIcon={{ children: 'visibility' }}
                  target="_blank"
                  variant="tertiary"
                  text={{ color: 'dark' }}
                  size="md"
                >
                  {t('link_view')}
                </ButtonNavigation>
              </li>
              <li>
                <ButtonNavigation
                  to={`${REACT_APP_MONOLITH_V2}/campaign/${promoId}/insights`}
                  firstIcon={{ children: 'bar_chart' }}
                  variant="tertiary"
                  text={{ color: 'dark' }}
                  size="md"
                >
                  {t('link_insights')}
                </ButtonNavigation>
              </li>
            </>
          )}
          <li>
            <ButtonNavigation
              to={`${REACT_APP_MONOLITH_V2}/campaign/${promoId}/entries`}
              firstIcon={{ children: 'person' }}
              variant="tertiary"
              text={{ color: 'dark' }}
              size="md"
            >
              {t('link_entries')}
            </ButtonNavigation>
          </li>
          <li>
            <ButtonNavigation
              to="/settings/integrations"
              firstIcon={{ children: 'terminal' }}
              variant="tertiary"
              text={{ color: 'dark' }}
              size="md"
            >
              {t('link_integrations')}
            </ButtonNavigation>
          </li>
          <li>
            <ButtonNavigation
              to={`/${getAccount.accountSlug}/promotions/${promoId}/share`}
              firstIcon={{ children: 'share' }}
              variant="tertiary"
              text={{ color: 'dark' }}
              size="md"
            >
              {t('link_share')}
            </ButtonNavigation>
          </li>
          {(promoStatus === PromoteStatus.DRAFT || promoStatus === PromoteStatus.SCHEDULED) && (
            <li>
              <ButtonNavigation
                to={micrositeUrlKey}
                firstIcon={{ children: 'visibility' }}
                target="_blank"
                variant="tertiary"
                text={{ color: 'dark' }}
                size="md"
              >
                {t('link_preview')}
              </ButtonNavigation>
            </li>
          )}
          <li>
            <ButtonNavigation
              to={`/${getAccount.accountSlug}/promotions/${promoId}/launch-control`}
              firstIcon={{ children: 'rocket' }}
              variant="tertiary"
              text={{ color: 'dark' }}
              size="md"
            >
              {t('link_launch_control')}
            </ButtonNavigation>
          </li>
          <li>
            <ButtonNavigation
              to={`${REACT_APP_MONOLITH_V2}/campaign/${promoId}/builder`}
              firstIcon={{ children: 'web' }}
              variant="tertiary"
              text={{ color: 'dark' }}
              size="md"
            >
              {t('link_builder')}
            </ButtonNavigation>
          </li>
          <li>
            <Button
              onClick={toggleModal}
              variant="tertiary"
              text={{ color: 'dark' }}
              firstIcon={{ children: 'file_copy' }}
              id={`popover-copy-${promoId}`}
              size="md"
            >
              {t('link_duplicate')}
            </Button>
          </li>

          {(promoStatus === PromoteStatus.DRAFT || promoStatus === PromoteStatus.SCHEDULED) && (
            <li>
              <ButtonConfirm
                actionButton={{
                  variant: 'tertiary',
                  text: { color: 'dark' },
                  firstIcon: { children: 'delete' },
                  size: 'md',
                  children: t('link_delete'),
                }}
                advice={{ text: 'Are you sure to delete?', color: 'gray-8' }}
                leftButton={{ variant: 'secondary', children: 'Cancel' }}
                rightButton={{
                  variant: 'primary',
                  children: t('link_delete'),
                  loading: loadingDeletePromotion,
                  onClick: () => toggleConfirmDelete(promoId),
                }}
              />
            </li>
          )}
        </ul>
      </Dropdown>
    </>
  );
}
