import Icon from '../../../componentsV2/DesignSystem/Icon';
import { PluginFiltersProps } from './interface';

import styles from './styles.module.scss';

export default function PluginFilters({ brand_color }: PluginFiltersProps): JSX.Element {
  return (
    <div className={styles['filter_section']}>
      <div className={styles['flex']}>
        <div
          className={styles['ready_to_play_btn']}
          style={{
            backgroundColor: `${brand_color}14`,
          }}
        >
          <div className={styles['text-align-center']} style={{ color: brand_color }}>
            Ready to Play{' '}
          </div>
          <div
            className={styles['not_played_counter']}
            style={{
              backgroundColor: brand_color,
            }}
          >
            4
          </div>
        </div>
        <div className={styles['already_played_btn']}>Already Played</div>
      </div>

      <div className={styles['filters-right-side-wrapper']}>
        <div className={styles['sort_select']}>
          Sort By: Newest
          <Icon customClass={styles['arrow-down-icon']} color="gray-92">
            keyboard_arrow_down
          </Icon>
        </div>
        <div className={styles['view_mode_carousel']}>
          <Icon customClass={styles['icon-carousel']} color="gray-92">
            view_column
          </Icon>
        </div>

        <div className={styles['view_mode_table']}>
          <Icon customClass={styles['icon-table']} color="gray-92">
            table_rows
          </Icon>
        </div>
      </div>
    </div>
  );
}
