import { IconAnimated } from '../../../../../../../componentsV2/DesignSystem/IconAnimated';
import ButtonNavigation from '../../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';
import { Paragraph } from '../../../../../../../componentsV2/DesignSystem/Typography';
import useShareLinks from '../../../ShareLinksProvider';
import styles from './styles.module.scss';

export default function Inbulk(): JSX.Element {
  const { downloadLink } = useShareLinks();

  return (
    <div className={styles['invitelinks-container']}>
      <div className={styles['container-icon-wrapper']}>
        <IconAnimated wrapper={{ color: 'green' }} icon={{ name: 'check' }} />

        {downloadLink?.quantity ? (
          <Paragraph size={2}>
            Your list with <span>{downloadLink.name || downloadLink.quantity + 'InviteLinks'}</span> is being created.
            You can:
          </Paragraph>
        ) : (
          <>
            <Paragraph size={2}>We are generating your InviteLinks.</Paragraph>
            <Paragraph size={2}>
              Remember to keep a record for who receives each InviteLink If you would like a record in BeeLiked, you can
              turn on the entry form for people to fill in their own details.
            </Paragraph>
          </>
        )}

        {downloadLink?.url && (
          <ButtonNavigation
            to={downloadLink.url}
            target="_blank"
            size="sm"
            variant="secondary"
            firstIcon={{ children: 'file_download' }}
          >
            Export List
          </ButtonNavigation>
        )}
      </div>
    </div>
  );
}
