import { useEffect, useMemo, useRef, useState } from 'react';
import SectionCollapsibleFull from '../../../../../../componentsV2/DesignSystem/Sections/CollapsibleFull';
import { StatusBadge } from '../../../../../../componentsV2/DesignSystem/Sections/interface';
import { Heading, Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import { InviteCreationMethod } from '../../../../../../ts';
import SendEmail from '../../../SendEmail';
import useSendEmail, { SendEmailProvider } from '../../../SendEmail/SendEmailProvider';
import { SendEmailRef } from '../../../SendEmail/interface';
import useShareLinks from '../../ShareLinksProvider';
import comingSoon from './comingSoon.svg';
import styles from './styles.module.scss';

function SectionCollapsible(): JSX.Element {
  const disabledSendEmail = true;
  const [inbulkId, setInbulkId] = useState<number | null>(null);
  const [sendType, setSendType] = useState<'In Bulk' | 'Individually'>();
  const {
    promotionSelected,
    downloadLink,
    generatorId,
    inviteListDataPersonalized,
    inviteTypeListMethod,
    inviteMethod,
    getSectionValue,
    setSectionExpanded,
  } = useShareLinks();
  const { stepSectionEmail, loadingSectionEmail, resetStepSectionEmail, enableSendEmail, setEnableSendEmail } =
    useSendEmail();

  const sendEmailSection = getSectionValue('sendEmailSection');

  const sendEmailRef = useRef<SendEmailRef>(null);

  const individualEmailList = useMemo(() => {
    return inviteListDataPersonalized
      .filter((invite) => !!invite.email)
      .map((invite) => ({
        label: invite?.first_name || invite?.email || '',
        value: invite?.email,
      }));
  }, [inviteListDataPersonalized]);

  useEffect(() => {
    if (inviteTypeListMethod === 'inbulk' && inviteMethod == InviteCreationMethod.PERSONALIZED) {
      setSendType('In Bulk');
    }

    if (inviteTypeListMethod === 'individually') {
      setSendType('Individually');
    }
  }, [inviteTypeListMethod, inviteMethod]);

  const codes = useMemo(() => {
    return sendType === 'Individually' ? inviteListDataPersonalized.map((invite) => invite.code) : undefined;
  }, [sendType, inviteListDataPersonalized]);

  useEffect(() => {
    if (sendType === 'In Bulk' && downloadLink && generatorId !== inbulkId) {
      setInbulkId(generatorId);
    }
  }, [sendType, generatorId, downloadLink]);

  useEffect(() => {
    setEnableSendEmail(sendType === 'Individually' && individualEmailList.length > 0);
  }, [sendType, individualEmailList]);

  useEffect(() => {
    resetStepSectionEmail();
  }, [sendType, codes]);

  useEffect(() => {
    if (stepSectionEmail === 'success') {
      setSectionExpanded('sendEmailSection', true, StatusBadge.icon);
    }
  }, [stepSectionEmail]);

  const nextSection = () => {
    if (sendEmailRef.current) {
      sendEmailRef.current.nextSection();
    }
  };

  const backSection = () => {
    if (sendEmailRef.current) {
      sendEmailRef.current.backSection();
    }
  };

  return (
    <>
      {sendEmailSection.badge.status !== StatusBadge.disabled &&
        (individualEmailList.length > 0 ||
          (inviteTypeListMethod === 'inbulk' && inviteMethod === InviteCreationMethod.PERSONALIZED)) && (
          <>
            {!disabledSendEmail ? (
              <SectionCollapsibleFull
                badge={sendEmailSection.badge}
                expanded={sendEmailSection.expanded}
                header={{
                  title:
                    stepSectionEmail === 'configuration'
                      ? `Send Email ${sendType ? ' - ' + sendType : ''}`
                      : 'Review and Send',
                }}
                {...(stepSectionEmail !== 'success' && {
                  footer: {
                    leftButton: {
                      children: stepSectionEmail === 'configuration' ? 'Cancel' : 'Back',
                      size: 'sm',
                      onClick: backSection,
                      variant: 'secondary',
                      loading: loadingSectionEmail,
                      disabled: loadingSectionEmail,
                    },
                    rightButton: {
                      children: stepSectionEmail === 'configuration' ? 'Next' : 'Everything OK, send email now',
                      size: 'sm',
                      onClick: nextSection,
                      loading: loadingSectionEmail,
                      disabled: loadingSectionEmail || (!enableSendEmail && stepSectionEmail === 'preview'),
                    },
                  },
                })}
              >
                <SendEmail
                  ref={sendEmailRef}
                  micrositeUrl={promotionSelected.micrositeUrl}
                  generateId={inbulkId}
                  {...(sendType === 'Individually' && {
                    emailListByCode: individualEmailList.length > 0 ? individualEmailList : undefined,
                    code: codes?.join(','),
                  })}
                />
              </SectionCollapsibleFull>
            ) : (
              <SectionCollapsibleFull
                badge={sendEmailSection.badge}
                expanded={sendEmailSection.expanded}
                header={{
                  title: 'Send Email',
                }}
                containerClassname={styles['container-section']}
              >
                <div>
                  <div className={styles['content']}>
                    <div>
                      <img src={comingSoon} alt={'Coming Soon'} />
                    </div>
                    <Heading size={4}>This feature is coming soon</Heading>
                    <Paragraph size={2}>
                      We are finishing the last tests and soon you will be able to send personalized emails with
                      InviteLink to your customers.
                    </Paragraph>
                  </div>
                </div>
              </SectionCollapsibleFull>
            )}
          </>
        )}
    </>
  );
}

export default function SendEmailSection(): JSX.Element {
  return (
    <SendEmailProvider>
      <SectionCollapsible />
    </SendEmailProvider>
  );
}
