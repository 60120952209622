import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Select from '../../../../componentsV2/DesignSystem/Inputs/Selects/Select';
import { OptionsProps } from '../../../../componentsV2/DesignSystem/Inputs/Selects/interface';
import { FontWeight, Paragraph } from '../../../../componentsV2/DesignSystem/Typography';
import useAccount from '../../../../hooks/useAccount';
import { Section } from './SendEmailProvider';
import SendEmailTest from './SendEmailTest';
import styles from './styles.module.scss';

interface PreviewEmailProps {
  handleSection: (section: Section) => void;
  micrositeUrl?: string;
  generatedId?: string;
  emailOptions?: OptionsProps[];
}

export default function PreviewEmail({ handleSection, micrositeUrl, emailOptions }: PreviewEmailProps): JSX.Element {
  const [userSelected, setUserSelected] = useState<OptionsProps>();

  const { getValues } = useFormContext();

  const { getAccount } = useAccount();

  const handleEmail = (value: string) => {
    if (emailOptions) {
      const user = emailOptions.find((email) => email.value === value);
      setUserSelected(user);
    }
  };

  useEffect(() => {
    if (emailOptions) {
      setUserSelected(emailOptions[0]);
    }
  }, []);

  return (
    <>
      <div className={styles['column']}>
        <div className={styles['configure-paragraphs']}>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            Email step preview
          </Paragraph>

          <Paragraph size={2}>Preview your email by testing combinations of custom variables</Paragraph>
        </div>
        <Select
          label="Select data"
          placeholder="Select an InviteLink"
          onChange={(id) => handleEmail(id.toString())}
          options={emailOptions}
          activeSearch
          defaultValue={userSelected?.value?.toString()}
          size="sm"
        />
      </div>
      <div className={styles['column']}>
        <div className={styles['column-container']}>
          <div className={styles['preview-title']}>
            <Paragraph size={2} weight={FontWeight.semiBold}>
              Email Preview
            </Paragraph>
            <Button
              type="button"
              firstIcon={{ children: 'edit' }}
              variant="secondary"
              size="sm"
              onClick={() => handleSection('configuration')}
            />
          </div>

          <div className={styles['preview-header']}>
            <div>
              <Paragraph size={3} weight={FontWeight.semiBold} color="gray-48">
                From Address
              </Paragraph>
              <Paragraph size={2} weight={FontWeight.semiBold}>
                promo@beeliked.com
              </Paragraph>
            </div>

            <div>
              <Paragraph size={3} weight={FontWeight.semiBold} color="gray-48">
                From Name
              </Paragraph>
              <Paragraph size={2} weight={FontWeight.semiBold}>
                {getAccount.accountName}
              </Paragraph>
            </div>

            <div>
              <Paragraph size={3} weight={FontWeight.semiBold} color="gray-48">
                Subject
              </Paragraph>
              <Paragraph size={2} weight={FontWeight.semiBold}>
                {getValues('subject')}
              </Paragraph>
            </div>
          </div>

          <div className={styles['content']}>
            <div className={styles['blank']}>
              <div className={styles['greeting']}>
                <div>{getValues('greeting')}</div>
                <div className={styles['user-name']}>{userSelected?.label}</div>
              </div>
              <div className={styles['email-content']}>
                {getValues('content')
                  .split('\n')
                  .map((item: string, key: number) => (
                    <React.Fragment key={key}>
                      {item}
                      <br />
                    </React.Fragment>
                  ))}
              </div>

              <div className={styles['enter-button-container']}>
                {getValues('enter_button_text') !== '' && (
                  <div>
                    <div className={styles['enter-button']}>{getValues('enter_button_text')}</div>
                  </div>
                )}
                <div className={styles['invite-link']}>{micrositeUrl}?inv=123456</div>
              </div>
              <div className={styles['email-signature']}>
                <div>
                  {getValues('signature')
                    .split('\n')
                    .map((item: string, key: number) => (
                      <React.Fragment key={key}>
                        {item}
                        <br />
                      </React.Fragment>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <SendEmailTest />
        </div>
      </div>
    </>
  );
}
