import { ApolloError, gql, useMutation } from '@apollo/client';

interface SendEmailCode {
  status: string;
  message: string;
}

interface InviteLinkSendEmailData {
  subject: string;
  content?: string;
  greeting?: string;
  enter_button_text?: string;
  signature?: string;
}

const SEND_EMAIL_CODE = gql`
  mutation sendEmailByCode($agencyId: ID!, $accountId: ID!, $code: String!, $data: InviteLinkSendEmailData) {
    sendEmailByCode(agencyId: $agencyId, accountId: $accountId, code: $code, data: $data) {
      status
      message
    }
  }
`;

export function useSendEmailByCodeMutation(): {
  loading: boolean;
  sendEmailByCode?: (variables: {
    agencyId: number;
    accountId: number;
    code: string;
    data: InviteLinkSendEmailData;
  }) => void;
  data: SendEmailCode | null;
  error?: ApolloError;
} {
  const [sendEmailByCodeMutation, { loading, data, error }] = useMutation<{
    sendEmailByCode: SendEmailCode;
  }>(SEND_EMAIL_CODE, {
    onError: (error: ApolloError) => {
      console.log({
        gql: 'SEND_EMAIL_CODE',
        apolloError: error,
      });
    },
  });

  const sendEmailByCode = (variables: {
    agencyId: number;
    accountId: number;
    code: string;
    data: InviteLinkSendEmailData;
  }) => {
    sendEmailByCodeMutation({ variables });
  };

  if (error) {
    return {
      loading: false,
      data: null,
      error,
    };
  }

  return {
    loading,
    sendEmailByCode,
    data: data?.sendEmailByCode || null,
  };
}
