import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import SpinnerAllScreen from '../../../../components/_molecules/SpinnerAllScreen';
import useAuth from '../../../../hooks/useAuth';
import { AccountData } from '../../../../ts';
import { removeAccountCookie, setAccountCookie } from '../../../../utils/accountCookie';
import { removeZapierAccessToken } from '../../../../utils/zapierStorage';
import { GET_ACCOUNT_BY_SLUG } from '../../Campaign/Content/graphql';

export default function CheckAccount(): JSX.Element {
  const { getMe } = useAuth();
  const { t } = useTranslation('translation');
  const location = useLocation();
  const history = useHistory();
  const { slug } = useParams<{ slug: string }>();

  useQuery<{ accountFindBySlug: AccountData }>(GET_ACCOUNT_BY_SLUG, {
    variables: {
      agency_id: getMe.agency_id,
      account_slug: slug,
    },
    onCompleted: (response) => {
      if (
        response.accountFindBySlug?.agency_id &&
        (getMe.superAdmin || getMe?.accounts?.indexOf(response.accountFindBySlug.id.toString()) > -1)
      ) {
        removeZapierAccessToken();
        setAccountCookie(response.accountFindBySlug.id.toString());
        const pathname = location.pathname;
        const hash = window.location.hash;
        const pos = pathname.indexOf('/page/');
        let urlToRedirect =
          pos > 0 ? pathname.slice(pos + '/page/'.length - 1) : location.pathname.replace('/r', '') + location.search;
        urlToRedirect += hash || '';
        window.location.replace(urlToRedirect);
      } else {
        history.push('/forbidden');
      }
    },
    onError: (error: ApolloError) => {
      console.log({
        gql: 'GET_ACCOUNT_BY_SLUG',
        variables: {
          agency_id: getMe.agency_id,
          account_slug: slug,
        },
        apolloError: error,
      });
      removeAccountCookie();
      history.push('/forbidden');
    },
  });

  return <SpinnerAllScreen text={t('Checking account')} />;
}
