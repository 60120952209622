import { ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import AlertMessage from '../../../../../../../components/AlertMessage';
import Icon from '../../../../../../../componentsV2/DesignSystem/Icon';
import { Button } from '../../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import SpinnerBox from '../../../../../../../componentsV2/DesignSystem/SpinnerBox';
import { FontWeight, Paragraph } from '../../../../../../../componentsV2/DesignSystem/Typography';
import { REACT_APP_LINK_PRIVACY_POLICY } from '../../../../../../../config/Env/ExternalLinks';
import styleModuleClasses from '../../../../../../../utils/styleModuleClasses';
import useShareLinks from '../../../ShareLinksProvider';
import { UploadCsvProps } from './interface';
import styles from './styles.module.scss';

export default function UploadCsv({
  filename,
  setFileInbulk,
  validation,
  setValidation,
  loading,
  clearFileInbulk,
}: UploadCsvProps): JSX.Element {
  const { setGeneratorId, inviteMethodPersonalized, setInviteMethodPersonalized } = useShareLinks();

  const handleChangeUploadField = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      if (e.target?.files[0]?.name.split('.').pop() !== 'csv') {
        setValidation(false);
        return;
      }

      setGeneratorId(null);
      setInviteMethodPersonalized('inbulk');
      setFileInbulk({
        name: e.target?.files[0]?.name,
        file: e.target.files[0],
      });
    }
  };

  const goToIndividually = () => {
    clearFileInbulk();
  };

  return (
    <div className={styles['wrapper-upload']}>
      <div className={styles['header-content']}>
        <div>
          <Paragraph size={1} weight={FontWeight.extraBold}>
            Create in Bulk
          </Paragraph>
          <Paragraph size={2}>
            The first row must have column headers to identify the fields. E.g. Email, first name, last name etc.
          </Paragraph>
        </div>
        <div>
          {inviteMethodPersonalized === 'inbulk' && (
            <Button firstIcon={{ children: 'groups' }} size="sm" variant="secondary" onClick={goToIndividually}>
              Create Individually
            </Button>
          )}
        </div>
      </div>
      <div
        className={styleModuleClasses(styles, 'upload', {
          loading: loading,
        })}
      >
        {!validation && (
          <AlertMessage type="alert-error">
            {filename ? 'File extension is not allowed. You can only upload CSV file.' : 'Please provide a file'}
          </AlertMessage>
        )}
        {loading ? (
          <div className={styles['upload-loading']}>
            <SpinnerBox />
          </div>
        ) : (
          <div className={styles['center-button']}>
            <label className={styles['button-upload']}>
              <input type="file" className="d-none" onChange={handleChangeUploadField} accept=".csv" />
              <Icon>file_upload</Icon> Upload CSV
            </label>
          </div>
        )}

        {!!filename && (
          <Paragraph size={2} color="primary">
            {filename}
          </Paragraph>
        )}
        {!loading && (
          <Paragraph size={2}>
            Data submitted will be treated with the utmost confidentiality and processed automatically by BeeLiked
            according to its{' '}
            <Link to={REACT_APP_LINK_PRIVACY_POLICY} target="_blank" rel="noreferrer">
              privacy policy
            </Link>
            .
          </Paragraph>
        )}
      </div>
    </div>
  );
}
