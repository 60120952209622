import { ApolloError, gql, useMutation } from '@apollo/client';

interface SendEmailByGenerateId {
  status: string;
  message: string;
}

interface InviteLinkSendEmailData {
  subject: string;
  content?: string;
  greeting?: string;
  enter_button_text?: string;
  signature?: string;
}

const SEND_EMAIL_GENERATEID = gql`
  mutation sendEmailByGenerateId($agencyId: ID!, $accountId: ID!, $generateId: ID!, $data: InviteLinkSendEmailData) {
    sendEmailByGenerateId(agencyId: $agencyId, accountId: $accountId, generateId: $generateId, data: $data) {
      status
      message
    }
  }
`;

export function useSendEmailByGenerateIdMutation(): {
  loading: boolean;
  sendEmailByGenerateId: (variables: {
    agencyId: number;
    accountId: number;
    generateId: number;
    data: InviteLinkSendEmailData;
  }) => void;
  data: SendEmailByGenerateId | null;
} {
  const [sendEmailByGenerateIdMutation, { loading, data }] = useMutation<{
    sendEmailByGenerateId: SendEmailByGenerateId;
  }>(SEND_EMAIL_GENERATEID, {
    onError: (error: ApolloError) => {
      console.log({
        gql: 'SEND_EMAIL_GENERATEID',
        apolloError: error,
      });
    },
  });

  const sendEmailByGenerateId = (variables: {
    agencyId: number;
    accountId: number;
    generateId: number;
    data: InviteLinkSendEmailData;
  }) => {
    sendEmailByGenerateIdMutation({ variables });
  };

  return {
    loading,
    sendEmailByGenerateId,
    data: data?.sendEmailByGenerateId || null,
  };
}
