import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { MainNavigationProps } from './interface';
import styleModuleClasses from '../../../utils/styleModuleClasses';
import useAuth from '../../../hooks/useAuth';
import useAccount from '../../../hooks/useAccount';
import Notifications from '../../Notifications';
import NavigationBarPlan from '../../NavigationBarPlan';
import NavigationSidebar from '../../NavigationSidebar';
import NavigationMobile from '../../NavigationMobile';
import NavigationProfileDropdown from '../../NavigationProfileDropdown';
import styles from './styles.module.scss';
import '../../../assets/scss/DesignSystemV2/spaces.scss';
import '../../../assets/scss/DesignSystemV2/rebot.scss';
import '../../../assets/scss/DesignSystemV2/display.scss';
import '../../../assets/scss/DesignSystemV2/aligns.scss';

export default function MainNavigation({ children, noPadding }: MainNavigationProps): JSX.Element {
  const [sidebar, setSidebar] = useState<boolean>(false);
  const history = useHistory();

  const { getMe } = useAuth();
  const { getAccount } = useAccount();

  const setSidebarCollapsed = () => {
    setSidebar((sidebar) => !sidebar);
  };

  return (
    <>
      <div className={styles['topbar-wrapper']}>
        <div className={styles['left-part']}>
          <div className={styles['logo-wrapper']}>
            <button
              className={styleModuleClasses(styles, 'hamburguer-button', 'hidden-down-md')}
              onClick={setSidebarCollapsed}
            >
              <span className="material-symbols-rounded">menu</span>
              <div className={styles['arrow-wrapper']}>
                <span
                  className="material-symbols-rounded"
                  style={{
                    transform: sidebar ? 'rotate(0deg) translate(0px, 0px)' : 'rotate(-180deg) translate(3px, 0px)',
                  }}
                >
                  chevron_right
                </span>
              </div>
            </button>
            <div className={styles['logo']} onClick={() => history.push('/dashboard')} />
          </div>
          <Link to="/promotions/create" className={styleModuleClasses(styles, 'cta-button', 'hidden-down-md')}>
            Create Promotion
          </Link>
        </div>
        <div className={styles['right-part']}>
          <Notifications />
          <div
            className={styleModuleClasses(styles, 'interactions-wrapper', 'hidden-down-sm', {
              freebee: getAccount.resumeEntries?.planType == 'freebee',
            })}
          >
            <NavigationBarPlan />
          </div>
          <NavigationProfileDropdown
            accountData={{
              accountName: getAccount.accountName,
              accountSlug: getAccount.accountSlug,
              accountPermission: getAccount.accountPermission,
            }}
            superAdmin={getMe.superAdmin}
            showSwitchAccount={getMe.accounts.length > 1}
          />
        </div>
      </div>

      <div className={styles['content-wrapper']}>
        <NavigationSidebar collapsed={sidebar} />
        <div
          className={styleModuleClasses(styles, 'children-wrapper', {
            'no-padding': Boolean(noPadding),
          })}
        >
          {children}
        </div>
      </div>

      <NavigationMobile
        accountSlug={getAccount.accountSlug}
        accountPermission={getAccount.accountPermission}
        userData={{
          email: getMe.email,
          firstName: getMe.name,
          lastName: getMe.family_name,
        }}
      />
    </>
  );
}
