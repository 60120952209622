import { ReactElement, ReactNode } from 'react';

export interface paragraphyProps {
  size: 1 | 2 | 3;
  weight?: FontWeight;
  children: ReactNode | ReactElement | ReactElement[];
  color?: string;
  ellipsis?: 1 | 2;
}
export interface headersProps {
  children: string;
  weight?: FontWeight;
  color?: Color;
  size: 1 | 2 | 3 | 4 | 5 | 6;
}

export enum Color {
  yellowBee = 'yellowBee',
  gray16 = 'gray-16',
  gray48 = 'gray-48',
}

export enum FontWeight {
  extraBold = 'extra-bold',
  semiBold = 'semi-bold',
  regular = 'regular',
}
