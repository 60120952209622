import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Input from '../../../../componentsV2/DesignSystem/Inputs/Input';
import Textarea from '../../../../componentsV2/DesignSystem/Inputs/Textarea';
import { FontWeight, Paragraph } from '../../../../componentsV2/DesignSystem/Typography';
import styles from './styles.module.scss';

export default function FormEmail(): JSX.Element {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  useEffect(() => {
    setTimeout(() => {
      setValue('greeting', 'Hi');
      setValue(
        'content',
        "You've been invited  into the draw to win our grand prize. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      );
      setValue('enter_button_text', 'Play Now');
      setValue('signature', 'Thank you');
    }, 300);
  }, []);

  return (
    <>
      <div className={styles['configure-paragraphs']}>
        <Paragraph size={2} weight={FontWeight.semiBold}>
          Configure the email sending options
        </Paragraph>

        <Paragraph size={2}>Customize your email sending preferences . E.g. Name, subject, etc.</Paragraph>
      </div>
      <div className={styles['form-inputs']}>
        <Input label="From Address" placeholder="promo@beeliked.com" disabled inputSize="sm" />
        <Input
          {...register('subject')}
          label="Subject"
          placeholder="What's the subject line for this email..."
          {...(errors.subject && { helperText: 'Please provide a valid value.', color: 'danger' })}
          inputSize="sm"
        />
      </div>
      <hr />
      <div className={styles['form-inputs']}>
        <Input
          {...register('greeting')}
          label="Greeting"
          {...(errors.greeting && { helperText: errors.greeting.message, color: 'danger' })}
          inputSize="sm"
        />
        <Textarea
          {...register('content')}
          label="Email Content"
          {...(errors.content && { helperText: errors.content.message, color: 'danger' })}
          inputSize="sm"
          rows={5}
        />

        <Input
          {...register('enter_button_text')}
          label="Enter Button"
          {...(errors.enter_button_text && {
            helperText: errors.enter_button_text.message,
            color: 'danger',
          })}
          inputSize="sm"
        />
        <Textarea
          {...register('signature')}
          label="Thank you"
          {...(errors.signature && { helperText: errors.signature.message, color: 'danger' })}
          inputSize="sm"
          rows={3}
        />
      </div>
    </>
  );
}
