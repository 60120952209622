import { ResumeEntriesData } from './allowance';

export interface AuthenticationData {
  access_token: string;
  expires_in?: number;
  refresh_expires_in?: number;
  refresh_token: string;
  scope?: string;
  session_state?: string;
  token_type?: string;
}

export interface AccountData {
  id: number;
  agency_id: number;
  name: string;
  slug: string;
  image: string;
  require_2fa?: number;
  last_access_at?: string;
  config_timezone?: string;
  config_date_format?: string;
  config_time_format?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  user_creator_id?: number;
  resumeEntries: ResumeEntriesData | null;
}

export interface ApiKeyData {
  id: number;
  service_client_id: string;
  service_secret: string;
}

export enum AccountPermission {
  account_admin = 'account_admin',
  account_manager = 'account_manager',
  account_editor = 'account_editor',
}
