import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { OptionsProps } from '../../../../componentsV2/DesignSystem/Inputs/Selects/interface';
import SpinnerBox from '../../../../componentsV2/DesignSystem/SpinnerBox';
import useAccount from '../../../../hooks/useAccount';
import useToast from '../../../../hooks/useToast';
import styleModuleClasses from '../../../../utils/styleModuleClasses';
import ConfigureEmail from './ConfigureEmail';
import EmailSuccessfullySent from './EmailSuccessfullySent';
import PreviewEmail from './PreviewEmail';
import PreviewSend from './PreviewSend';
import useSendEmail, { Section } from './SendEmailProvider';
import ValidationResolver from './ValidationResolver';
import { SendEmailRef } from './interface';
import styles from './styles.module.scss';
import { useLazyFetchInviteLinksByAccount } from './useLazyFetchInviteLinksByAccount';
import { useSendEmailByCodeMutation } from './useSendEmailByCodeMutation';
import { useSendEmailByGenerateIdMutation } from './useSendEmailByGenerateIdMutation';

export interface SendEmailVariables {
  testEmailTo?: string;
  subject: string;
  greeting?: string;
  content?: string;
  enter_button_text?: string;
  signature?: string;
}

interface SendEmailProps {
  micrositeUrl?: string;
  generateId?: number | null;
  code?: string;
  firstSection?: Section;
  invitelinkUrl?: string;
  firstName?: string;
  emailListByCode?: OptionsProps[];
}

export default React.forwardRef<SendEmailRef, SendEmailProps>(function SendEmail(
  { micrositeUrl, generateId, code, firstSection, invitelinkUrl, firstName, emailListByCode }: SendEmailProps,
  ref,
) {
  const [section, setSection] = useState<Section>(firstSection || 'configuration');

  const { setStepSectionEmail, setLoadingSectionEmail, setEnableSendEmail, stepSectionEmail } = useSendEmail();

  const { getAccount } = useAccount();
  const { toast } = useToast();

  const { data: dataInviteLink, loading: loadingInviteLink, fetchInviteLinks } = useLazyFetchInviteLinksByAccount();

  useEffect(() => {
    if (generateId) {
      fetchInviteLinks({
        accountId: getAccount.accountId,
        page: 1,
        filters: {
          generator_id: generateId.toString(),
        },
      });
    }
  }, [generateId]);

  useEffect(() => {
    if (!firstSection) {
      setSection(stepSectionEmail);
    }
  }, [stepSectionEmail, firstSection]);

  const emailOptions = useMemo(() => {
    if (dataInviteLink) {
      const response = dataInviteLink
        .filter((invite) => invite.data && Object.keys(invite.data).length > 0 && !!invite.data['email'])
        .map((invite) => ({
          label: invite.data['first-name'] || invite.data['email'],
          value: invite.data['email'],
        }));
      return response;
    }
    return [];
  }, [dataInviteLink]);

  useEffect(() => {
    if (dataInviteLink.length > 0) {
      const checkNoEmail =
        dataInviteLink.findIndex(
          (invite) => invite.data && Object.keys(invite.data).length > 0 && !!invite.data['email'],
        ) > -1;

      setEnableSendEmail(checkNoEmail);
    }
  }, [dataInviteLink]);

  const {
    sendEmailByGenerateId,
    loading: loadingSendEmailByGenerateId,
    data: dataSendEmailByGenerateId,
  } = useSendEmailByGenerateIdMutation();

  const {
    sendEmailByCode,
    loading: loadingSendEmailByCode,
    data: dataSendEmailByCode,
    error,
  } = useSendEmailByCodeMutation();

  const methods = useForm<SendEmailVariables>({
    resolver: ValidationResolver,
    defaultValues: {
      subject: '',
      greeting: '',
      content: '',
      enter_button_text: '',
      signature: '',
    },
  });

  const { handleSubmit, getValues, setValue, setError, clearErrors } = methods;

  useEffect(() => {
    if (error) {
      toast('error', 'An error occurred when trying to send the email');
    }
  }, [error]);

  useEffect(() => {
    if (dataSendEmailByGenerateId || dataSendEmailByCode) {
      setSection('success');
      setStepSectionEmail('success');
    }
  }, [dataSendEmailByGenerateId, dataSendEmailByCode]);

  useEffect(
    () => setLoadingSectionEmail(loadingSendEmailByGenerateId || loadingSendEmailByCode),
    [loadingSendEmailByGenerateId, loadingSendEmailByCode],
  );

  const submit = handleSubmit((variables) => {
    const data = {
      ...variables,
      testEmailTo: undefined,
    };

    if (code) {
      sendEmailByCode?.({
        agencyId: getAccount.agencyId,
        accountId: getAccount.accountId,
        code,
        data,
      });
    } else if (generateId) {
      sendEmailByGenerateId({
        agencyId: getAccount.agencyId,
        accountId: getAccount.accountId,
        generateId,
        data,
      });
    }
  });

  const handleSection = (option: Section) => {
    setSection(option);
  };

  const nextSection = () => {
    clearErrors('subject');
    const subject = getValues('subject');
    if (section === 'configuration') {
      if (subject !== '') {
        setStepSectionEmail('preview');
        setSection('preview');
      } else {
        setError('subject', { type: 'required' });
      }
    } else if (section === 'preview-send') {
      if (subject !== '') {
        submit();
      } else {
        setError('subject', { type: 'required' });
      }
    } else {
      submit();
    }
  };

  const backSection = () => {
    setSection('configuration');
    setStepSectionEmail('configuration');

    if (section === 'configuration') {
      setValue('content', '');
      setValue('subject', '');
      setValue('greeting', '');
      setValue('enter_button_text', '');
      setValue('signature', '');
      setValue('testEmailTo', '');
    }
  };

  useImperativeHandle(ref, () => ({
    nextSection: nextSection,
    backSection: backSection,
  }));

  if (loadingInviteLink) {
    return (
      <div className={styles['loading']}>
        <SpinnerBox />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={submit}>
        <div
          className={styleModuleClasses(styles, 'wrapper', {
            'one-column': section === 'success',
          })}
        >
          {section === 'preview-send' && <PreviewSend invitelinkUrl={invitelinkUrl} firstName={firstName} />}
          {section === 'configuration' && <ConfigureEmail />}
          {section === 'preview' && (
            <PreviewEmail
              handleSection={handleSection}
              micrositeUrl={micrositeUrl}
              emailOptions={emailListByCode || emailOptions}
            />
          )}
          {section === 'success' && <EmailSuccessfullySent />}
        </div>
      </form>
    </FormProvider>
  );
});
