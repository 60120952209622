import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const initialSchema = yup.object({
  id: yup.number(),
  app_uid: yup.string().optional(),
  name: yup.string().required('The Name is required.'),
  brand_color: yup.string().optional(),
  primary_color: yup.string().optional(),
  background_color: yup.string().optional(),
  typography: yup.string().optional(),
  image_file: yup.string().optional(),
  image_align: yup.string().optional(),
  greeting_message: yup.string().optional(),
  heading_message: yup.string().optional(),
  description_message: yup.string().optional(),
  countdown_text_message: yup.string().optional(),
  days: yup.string().optional(),
  hours: yup.string().optional(),
  minutes: yup.string().optional(),
  cta_text_message: yup.string().optional(),
  powered_by_blkd: yup.boolean().optional(),
  embed_type: yup.string().optional(),
});

const PluginValidationResolver = yupResolver(initialSchema);

export default PluginValidationResolver;
