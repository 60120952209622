import styleModuleClasses from '../../../../utils/styleModuleClasses';
import { FontWeight, paragraphyProps } from '../interface';
import styles from './styles.module.scss';

export function Paragraph({ size, weight, color, children, ellipsis }: paragraphyProps): JSX.Element {
  return (
    <p
      className={styleModuleClasses(styles, {
        p1: size === 1,
        p2: size === 2,
        p3: size === 3,
        'ellipsis-1': ellipsis === 1,
        'ellipsis-2': ellipsis === 2,
        [color as string]: !!color,
        [weight as string]: !!weight,
        [FontWeight.regular]: !weight,
      })}
    >
      {children}
    </p>
  );
}
