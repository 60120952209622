import { ApolloError, gql, useMutation } from '@apollo/client';

interface CreateInbulkFileInvite {
  id: number;
  file_headers: string[];
  quantity: number;
}

const CREATE_INBULK_FILE_INVITE = gql`
  mutation createInbulkFileInvite($agency_id: ID!, $account_id: ID!, $promo_id: ID!, $url: String!, $file: Upload!) {
    createInbulkFileInvite(
      agency_id: $agency_id
      account_id: $account_id
      promo_id: $promo_id
      url: $url
      file: $file
    ) {
      id
      file_headers
      quantity
    }
  }
`;

export function useCreateInbulkFileInviteMutation(): {
  loading: boolean;
  createInbulkFileInvite: (variables: {
    agency_id: number;
    account_id: number;
    promo_id: number;
    url: string;
    file: File;
  }) => void;
  data: CreateInbulkFileInvite | null;
} {
  const [createInbulkFileInviteMutation, { loading, data }] = useMutation<{
    createInbulkFileInvite: CreateInbulkFileInvite;
  }>(CREATE_INBULK_FILE_INVITE, {
    onError: (error: ApolloError) => {
      console.log({
        gql: 'CREATE_FILE_DOWNLOAD',
        apolloError: error,
      });
    },
  });

  const createInbulkFileInvite = (variables: {
    agency_id: number;
    account_id: number;
    promo_id: number;
    url: string;
    file: File;
  }) => {
    createInbulkFileInviteMutation({ variables });
  };

  return {
    loading,
    createInbulkFileInvite,
    data: data?.createInbulkFileInvite || null,
  };
}
