import { useEffect, useMemo } from 'react';
import Select from '../../../../../componentsV2/DesignSystem/Inputs/Selects/Select';
import { PillsColors } from '../../../../../componentsV2/DesignSystem/Pills/interface';
import SectionCollapsibleHalf from '../../../../../componentsV2/DesignSystem/Sections/CollapsibleHalf';
import { StatusBadge } from '../../../../../componentsV2/DesignSystem/Sections/interface';
import { Paragraph } from '../../../../../componentsV2/DesignSystem/Typography';
import useFetchPromotionsSimpleList from '../../../../../graphql/queries/useFetchPromotionsSimpleList';
import useAccount from '../../../../../hooks/useAccount';
import useQueryString from '../../../../../hooks/useQueryString';
import { PromotionVisibility } from '../../../../../ts';
import capitalize from '../../../../../utils/capitalize';
import useShareLinks from '../ShareLinksProvider';
import styles from './styles.module.scss';

export default function SelectPromotion(): JSX.Element {
  const query = useQueryString();

  const promoId = query.get('promo_id') || undefined;

  const { getAccount } = useAccount();

  const { promotionSelected, setPromotionSelected } = useShareLinks();

  const { data, loading } = useFetchPromotionsSimpleList(getAccount.accountId, { status: 'draft,scheduled,live' });

  const dataPromotions = useMemo(() => {
    return data?.map((promotion) => ({
      label: promotion.name,
      value: promotion.id,
      pill: {
        children: promotion.visibility,
        color: promotion.visibility === PromotionVisibility.PUBLIC ? PillsColors.green : PillsColors.primary,
      },
    }));
  }, [data]);

  const handlePromotion = (id: number) => {
    const promotionSelected = data?.find((item) => Number(item.id) === Number(id));
    if (promotionSelected) {
      setPromotionSelected(promotionSelected);
    }
  };

  useEffect(() => {
    if (promoId) {
      handlePromotion(Number(promoId));
    } else if (data) {
      setPromotionSelected(data[0]);
    }
  }, [data]);

  return (
    <SectionCollapsibleHalf
      badge={{
        status: promotionSelected?.id ? StatusBadge.icon : StatusBadge.enabled,
        icon: { name: 'done', color: 'green' },
        number: 1,
      }}
      expanded={true}
      header={{ title: 'Select Promotion' }}
      containerClassname={styles['container-section']}
      showButton={{ firstIcon: { children: 'question_mark' }, size: 'sm' }}
      showButtonPopover={{
        header: {
          title: 'Take a moment to learn all the ways you can share your promotion.',
        },
        color: 'dark',
        size: 'sm',
        buttonsFooter: [
          {
            children: 'Learn More',
            size: 'sm',
            onClick: () => window.open('https://www.beeliked.com'),
            fullWidth: true,
          },
        ],
      }}
    >
      <>
        <Select
          label="Select a promotion you want to share"
          placeholder={loading ? 'Loading promotions' : 'Select a promotion'}
          onChange={(id) => handlePromotion(Number(id))}
          options={dataPromotions}
          pill={{
            children: promotionSelected?.visibility ? capitalize(promotionSelected.visibility) : '',
            color: PillsColors.green,
          }}
          activeSearch
          disabled={loading}
          defaultValue={promotionSelected ? promotionSelected.id?.toString() : ''}
        />

        {!!promotionSelected && (
          <Paragraph size={2}>
            {promotionSelected.visibility === PromotionVisibility.PUBLIC
              ? 'This promotion is public and so anyone with the link can enter.'
              : 'Your promotion is private. Each person will need their own InviteLink to enter.'}
          </Paragraph>
        )}
      </>
    </SectionCollapsibleHalf>
  );
}
