import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface HubspotConversationsStatus {
  loaded: boolean;
  pending: boolean;
}

interface HubspotConversationsContextType {
  toggleWidget: () => void;
  isOpen: boolean;
  unreadMessagesCount: number;
  openConversation: (identificationToken: string) => void;
  getStatus: () => HubspotConversationsStatus;
}

interface Props {
  children: JSX.Element | JSX.Element[];
  identificationEmail: string;
}

const HubspotConversationsContext = createContext<HubspotConversationsContextType | null>(null);

export function HubspotConversationsProvider({ children, identificationEmail }: Props): JSX.Element {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0);

  const hideWidget = useCallback(() => {
    setIsOpen(false);
  }, []);

  const showWidget = useCallback(() => {
    if (!isReady) {
      return;
    }

    window.HubSpotConversations.widget.load();
    window.HubSpotConversations.widget.open();

    setIsOpen(true);
  }, [isReady]);

  const toggleWidget = useCallback(() => {
    if (isOpen) {
      hideWidget();
    } else {
      showWidget();
    }
  }, [hideWidget, isOpen, showWidget]);

  const onConversationsReady = useCallback(() => {
    setIsReady(true);
  }, []);

  useEffect(() => {
    if (window.HubSpotConversations) {
      onConversationsReady();
    } else {
      window.hsConversationsOnReady = [onConversationsReady];
    }
  }, [onConversationsReady]);

  const openConversation = (identificationToken: string) => {
    if (identificationToken && identificationEmail) {
      window.hsConversationsSettings = {
        ...window.hsConversationsSettings,
        identificationEmail,
        identificationToken,
      };
      if (window.HubSpotConversations) {
        window.HubSpotConversations.widget.load();
        setIsOpen(true);
      }
    }
  };

  const getStatus = () => {
    return window.HubSpotConversations.widget.status();
  };

  useEffect(() => {
    if (!isReady) {
      return;
    }

    function listener(payload: { unreadCount: number }) {
      setUnreadMessagesCount(payload.unreadCount);
    }

    window.HubSpotConversations.on('unreadConversationCountChanged', listener);

    return () => {
      window.HubSpotConversations.off('unreadConversationCountChanged', listener);
    };
  }, [isReady]);

  useEffect(() => {
    if (!isReady) {
      return;
    }

    window.HubSpotConversations.widget.refresh();
  }, [isReady]);

  return (
    <HubspotConversationsContext.Provider
      value={{ isOpen, toggleWidget, unreadMessagesCount, openConversation, getStatus }}
    >
      {children}
      {/*
        @TODO: To test on dev
        {ReactDOM.createPortal(
          <div
            className="hubspot-conversations-widget-container"
            style={{ display: isOpen ? 'block' : 'none' }}
            id="hubspot-conversations-inline-embed-selector"
          />,
          document.body,
        )}
      */}
    </HubspotConversationsContext.Provider>
  );
}

export function useHubspotConversations(): HubspotConversationsContextType {
  const context = useContext(HubspotConversationsContext) as HubspotConversationsContextType;

  if (context === null) {
    throw new Error('useHubspotConversations must be used within HubspotConversationsProvider');
  }

  return context;
}
