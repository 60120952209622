import { ApolloError, gql, useMutation } from '@apollo/client';

const UPDATE_PLUGIN = gql`
  mutation updateRewardsPlugin($accountId: ID!, $data: RewardsPluginInputData) {
    updateRewardsPlugin(accountId: $accountId, data: $data) {
      id
      name
      brand_color
      primary_color
      background_color
      typography
      image_file
      image_align
      greeting_message
      heading_message
      description_message
      countdown_text_message
      days
      hours
      minutes
      cta_text_message
      powered_by_blkd
      embed_type
    }
  }
`;

interface UpdatePluginResult {
  id?: number;
  name: string;
  brand_color?: string;
  primary_color?: string;
  background_color?: string;
  typography?: string;
  image_file?: string;
  image_align?: string;
  greeting_message?: string;
  heading_message?: string;
  description_message?: string;
  countdown_text_message?: string;
  days?: string;
  hours?: string;
  minutes?: string;
  cta_text_message?: string;
  powered_by_blkd?: boolean;
  embed_type?: string;
}

interface UpdatePluginDataVariables {
  id?: number;
  name: string;
  brand_color?: string;
  primary_color?: string;
  background_color?: string;
  typography?: string;
  image_file?: string;
  image_align?: string;
  greeting_message?: string;
  heading_message?: string;
  description_message?: string;
  countdown_text_message?: string;
  days?: string;
  hours?: string;
  minutes?: string;
  cta_text_message?: string;
  powered_by_blkd?: boolean;
  embed_type?: string;
}

export function useMutationUpdatePlugin(): {
  loading: boolean;
  updateRewardsPlugin: (variables: { accountId: number; data: UpdatePluginDataVariables }) => void;
  data: UpdatePluginResult | null;
} {
  const [updatePluginMutation, { loading, data }] = useMutation<{ updateRewardsPlugin: UpdatePluginResult }>(
    UPDATE_PLUGIN,
    {
      onError: (error: ApolloError) => {
        console.log({
          gql: 'UPDATE_PLUGIN',
          apolloError: error,
        });
      },
    },
  );

  const updateRewardsPlugin = (variables: { accountId: number; data: UpdatePluginDataVariables }) => {
    updatePluginMutation({ variables });
  };

  return {
    loading,
    updateRewardsPlugin,
    data: data?.updateRewardsPlugin || null,
  };
}
