export const INVITES_IN_BULK_STATUS = [
  { id: -1, name: 'Failed', style: 'danger' },
  { id: 0, name: 'Created', style: 'success' },
  { id: 1, name: 'Processing', style: 'warning' },
  { id: 2, name: 'Generated', style: 'success' },
  { id: 3, name: 'Finished', style: 'success' },
  { id: 4, name: 'Expired', style: 'grey' },
  { id: 5, name: 'Pending', style: 'warning' },
];

export const PAGINATION_LIMIT_DEFAULT = {
  COUPONS_LIST: 10,
  COLLECTIONS_LIST: 10,
};

export const ACCOUNT_PERMISSION = {
  account_admin: 10,
  account_manager: 20,
  account_editor: 30,
};

export const BREAKPOINTS = {
  XS: 0,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1400,
  FULLHD: 1920,
};

export const PLAN_VALUES: { [key: string]: number } = {
  essentials: 100,
  standard: 200,
  premium: 500,
  freebee: 10,
  enterprise: 600,
  '': 10,
};

export const ACCOUNTS_UNLIMITED_PROMOTIONS_IN_LIVE = ['3227', '81'];
export const ACCOUNTS_ACCESS_SHARE_CENTER = [
  '8064',
  '3190',
  '4079',
  '3193',
  '5818',
  '7375',
  '9500',
  '10433',
  '8393',
  '5272',
  '11069',
  '10253',
  '11688',
  '11671',
  '10234',
  '11155',
  '11156',
];

export const ROLE_MAPPING = {
  ACCOUNT_ADMIN: {
    description: 'Admin',
  },
  ACCOUNT_MANAGER: {
    description: 'Manager',
  },
  ACCOUNT_EDITOR: {
    description: 'Editor',
  },
};

export const USER_PERMISSION_LIST = [
  { description: 'Access Promotions Page', editor: true, manager: true, admin: true },
  { description: 'Access Builder', editor: true, manager: true, admin: true },
  { description: 'Access Launch Control', editor: false, manager: true, admin: true },
  { description: 'Access Share Centre', editor: false, manager: true, admin: true },
  { description: 'Access Audience', editor: false, manager: true, admin: true },
  { description: 'Access Reward Centre', editor: false, manager: true, admin: true },
  { description: 'Access Developer Page', editor: false, manager: true, admin: true },
  { description: 'Access Account Details', editor: false, manager: true, admin: true },
  { description: 'Access Integrations', editor: false, manager: true, admin: true },
  { description: 'Access Insights Page', editor: false, manager: true, admin: true },
  { description: 'Access Entries Page', editor: false, manager: true, admin: true },
  { description: 'Access Subscription Page', editor: false, manager: false, admin: true },
  { description: 'Access Manage Users', editor: false, manager: false, admin: true },
];

export const ACCOUNT_PERMISSION_LIST: { [key: string]: string } = {
  s_a: 'super_admin',
  ag_m: 'agency_manager',
  ag_e: 'agency_editor',
  a: 'account_admin',
  m: 'account_manager',
  e: 'account_editor',
  ag_api: 'agency_api',
  ac_a: 'account_api',
};
