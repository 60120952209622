import { ApolloError, gql, useQuery } from '@apollo/client';

const PLUGIN = gql`
  query getRewardsPlugin($accountId: ID!, $pluginId: ID!) {
    getRewardsPlugin(accountId: $accountId, pluginId: $pluginId) {
      id
      name
      brand_color
      primary_color
      background_color
      typography
      image_file
      image_align
      greeting_message
      heading_message
      description_message
      countdown_text_message
      days
      hours
      minutes
      cta_text_message
      powered_by_blkd
      embed_type
      app_uid
    }
  }
`;

export interface Plugin {
  id: number;
  name: string;
  brand_color?: string;
  primary_color?: string;
  background_color?: string;
  typography?: string;
  image_file?: string;
  image_align?: string;
  greeting_message?: string;
  heading_message?: string;
  description_message?: string;
  countdown_text_message?: string;
  days?: string;
  hours?: string;
  minutes?: string;
  cta_text_message?: string;
  powered_by_blkd?: boolean;
  embed_type?: string;
  app_uid?: string;
}

export function useFetchPlugin(
  accountId: number,
  pluginId: number,
): {
  loading: boolean;
  data: Plugin | null;
  error?: ApolloError;
} {
  const { data, loading, error } = useQuery<{ getRewardsPlugin: Plugin }>(PLUGIN, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId,
      pluginId,
    },
    skip: !pluginId,
    onError: (error: ApolloError) => {
      console.log({
        gql: 'PLUGIN',
        apolloError: error,
      });
    },
  });

  if (loading) {
    return {
      loading: true,
      data: null,
    };
  }

  if (error) {
    return {
      loading: false,
      data: null,
      error,
    };
  }

  return {
    loading: false,
    data: data?.getRewardsPlugin || null,
  };
}
